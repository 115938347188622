// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'

const ChannelDatalength = createSlice({
  name:"ArrowiconToggleproductId",
  initialState: {
    length: 0,
  },
  reducers:{
    Channellength(state,action){
         state.length = action.payload
      },
  }
}) 
export default ChannelDatalength.reducer;
export const {Channellength} = ChannelDatalength.actions;