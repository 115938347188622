// reducers/counterReducer.js
import {createSlice} from '@reduxjs/toolkit'
const sellerproductshowinventory = createSlice({
  name: 'sellerproductshowinventory',
  initialState: {
    payloadData: '',
  },
  reducers: {
    sellerProductpayloaddata(state, action) {
      return action.payload
    },
  },
})
export default sellerproductshowinventory.reducer
export const {sellerProductpayloaddata} = sellerproductshowinventory.actions
