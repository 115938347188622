import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  activeChannel: [],
};

const activeChannelSlice = createSlice({
  name: 'activeChannel',
  initialState,
  reducers: {
    setActiveChannel: (state, action) => {
      const { productId, channelId } = action.payload;

      const existingChannelIndex = state.activeChannel.findIndex(
        channel => channel.productId === productId && channel.channelId === channelId
      );

      if (existingChannelIndex !== -1) {
        state.activeChannel.splice(existingChannelIndex, 1);
      } else {
        state.activeChannel.push({ productId, channelId });
      }
    },
    clearActiveChannel: (state) => {
      state.activeChannel = [];
    },
    removeChannelsByProductId: (state, action) => {
      const { productId } = action.payload;
      // Remove only the channels with the specified productId
      state.activeChannel = state.activeChannel.filter(
        channel => channel.productId !== productId
      );
    },
  },
});

export const { setActiveChannel, clearActiveChannel, removeChannelsByProductId } = activeChannelSlice.actions;

export default activeChannelSlice.reducer;
