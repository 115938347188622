// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const sellerCloudApicalltoggle = createSlice({
  name:"sellerCloudApicalltoggle",
  initialState: {
    isToggled: false,
    productId:""
  },
  reducers:{
    toggle: state => {
        state.isToggled = !state.isToggled;
      },
        setProductId: (state, action) => {
      state.productId = action.payload;
    },
  }
}) 
export default sellerCloudApicalltoggle.reducer;
export const {toggle,setProductId} = sellerCloudApicalltoggle.actions;