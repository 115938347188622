// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'

const ArrowiconToggleproductId = createSlice({
  name:"ArrowiconToggleproductId",
  initialState: {
    togglearrowproductid: null,
  },
  reducers:{
    arrowiconToggleproductId(state,action){
         state.togglearrowproductid = action.payload
      },
  }
}) 
export default ArrowiconToggleproductId.reducer;
export const {arrowiconToggleproductId} = ArrowiconToggleproductId.actions;