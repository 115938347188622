// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const pageNumber = createSlice({
  name:"pageNumber",
  initialState:1,
  reducers:{
    PageNumber(state,action){
      return (action.payload)
    },
    ResetpageNumber(){
      return "1"
    }
  }
}) 
export default pageNumber.reducer;
export const {PageNumber,ResetpageNumber}= pageNumber.actions ;