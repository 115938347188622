/* eslint-disable react-hooks/exhaustive-deps */
import {useQueryRequest} from '../../core/QueryRequestProvider'
import Select from 'react-select'
import {useQueryResponse} from "../../core/QueryResponseProvider";
import {QUERIES, isNotEmpty} from "../../../../../../_metronic/helpers";
import {getAllAmazonAccounts} from "../../../../amazon-account/amazon-accounts-list/core/_requests";
import {useQuery} from "react-query";
import { ReactOptionType } from '../../../../../generic-types/react-select-types';
// import {ReactOptionType} from "../../../../amazon-product/amazon-product-list/components/MainBody";

const CurrencyListAmazonAccountComponent = () => {
    const {updateState, state} = useQueryRequest()
    const {isLoading} = useQueryResponse()
    const {
        isLoading: isAmazonAccountsLoading,
        data: amazonAccounts,
    } = useQuery(
        `${QUERIES.AMAZON_ACCOUNTS}`,
        () => {
            return getAllAmazonAccounts()
        },
        {
            cacheTime: 0,
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            select: (data): ReactOptionType[] => {
                const options = data.data?.map((amazon_account) => {
                    const value = amazon_account.id || +'';
                    const label = amazon_account.email || '';
                    return {
                        value: value,
                        label: label
                    };
                })
                return (options === undefined) ? [] : options;
            }
        }
    )

    const filterData = (option) => {
        updateState({
            ...state,
            filter: {...state.filter, amazon_account: option.value},
        })
    }

    return (
        // <div className='card-title'>
            <div className='d-flex align-items-center my-1'>
                <Select
                    value={amazonAccounts?.find((option): ReactOptionType | null => {
                        if (option.value === +state.filter.amazon_account) {
                            return option;
                        }
                        return null;
                    })}
                    className={'w-250px'}
                    isDisabled={(isAmazonAccountsLoading)}
                    isLoading={isAmazonAccountsLoading}
                    isClearable
                    isSearchable
                    options={amazonAccounts}
                    onChange={filterData}
                    placeholder ="Select Amazon Account"
                />
            </div>
        // </div>
    )
}

export {CurrencyListAmazonAccountComponent}
