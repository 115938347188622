// reducers/amazonFeedSlice.js
import { createSlice } from '@reduxjs/toolkit';

const amazonFeed = createSlice({
  name: "amazonFeed",
  initialState: null,
  reducers: {
    UpdateAmazonFeedAction(state, action) {
      return action.payload;
    },
    ResetUpdateAmazonFeedAction() {
      return null;
    }
  }
});

export default amazonFeed.reducer;
export const { UpdateAmazonFeedAction, ResetUpdateAmazonFeedAction } = amazonFeed.actions;
