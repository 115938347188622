// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'

const SellerproductShowchannel = createSlice({
  name:"SellerproductShowchannel",
  initialState: {
    toggle: null,
  },
  reducers:{
    SellerproductShowchannelonebyone(state,action){
         state.toggle = action.payload
      },
  }
}) 
export default SellerproductShowchannel.reducer;
export const {SellerproductShowchannelonebyone} = SellerproductShowchannel.actions;