// reducers/counterReducer.js
import {createSlice} from '@reduxjs/toolkit'
const Spinner = createSlice({
  name: 'Spinner',
  initialState: {
    spinner: null,
  },
  reducers: {
    Spinnerr(state, action) {
      state.spinner = action.payload
    },
  },
})
export default Spinner.reducer
export const {Spinnerr} = Spinner.actions
