import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers/crud-helper/custom-models'
import {AmazonAccount, GetAmazonAccountsQueryResponse} from './_models'
import {Role} from "../../../roles/roles-list/core/_models";

const API_URL = process.env.REACT_APP_KT_API_URL
const AMAZON_ACCOUNT_API_URL = `${API_URL}/amazon/account`
const URL = `${API_URL}/seller/account`

// * Read
const getAmazonAccounts = (query: string): Promise<GetAmazonAccountsQueryResponse> => {
  return axios
    .get(`${AMAZON_ACCOUNT_API_URL}?${query}`)
    .then((d: AxiosResponse<GetAmazonAccountsQueryResponse>) => d.data)
}

const getSellerAccount = () :Promise<any> => {
  return axios
  .get(`${URL}/selleraccount`)
  .then((d: AxiosResponse<GetAmazonAccountsQueryResponse>) => d.data  )
}

const getAllAmazonAccounts = (): Promise<Response<AmazonAccount[]>> => {
  return axios
      .get(`${AMAZON_ACCOUNT_API_URL}/list`)
      .then((d: AxiosResponse<Response<AmazonAccount[]>>) => d.data)
}

const getAmazonAccountById = (id: ID): Promise<AmazonAccount | undefined> => {
  return axios
    .get(`${AMAZON_ACCOUNT_API_URL}/${id}`)
    .then((response: AxiosResponse<Response<AmazonAccount>>) => response.data)
    .then((response: Response<AmazonAccount>) => response.data)
}

const createAmazonAccount = (amazon_account: AmazonAccount): Promise<AmazonAccount | undefined> => {
  return axios
      .post(AMAZON_ACCOUNT_API_URL, amazon_account)
      .then((response: AxiosResponse<Response<AmazonAccount>>) => response.data)
      .then((response: Response<AmazonAccount>) => response.data)
}

const deleteAmazonAccount = (amazon_acount_id: ID): Promise<void> => {
  return axios.delete(`${AMAZON_ACCOUNT_API_URL}/${amazon_acount_id}`).then(() => {})
}

const deleteSelectedAmazonAccounts = (amazon_acount_id: Array<ID>): Promise<void> => {
  const requests = amazon_acount_id.map((id) => axios.delete(`${AMAZON_ACCOUNT_API_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const resynchronizeAmazonAccount = (amazon_acount_id: ID): Promise<void> => {
  return axios.get(`${AMAZON_ACCOUNT_API_URL}/synchronize/${amazon_acount_id}`).then(() => {})
}

export {getAmazonAccounts,getSellerAccount,resynchronizeAmazonAccount, getAmazonAccountById, createAmazonAccount, deleteAmazonAccount, getAllAmazonAccounts, deleteSelectedAmazonAccounts}
