import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {Normalprice_productaprovel} from '../pages/dashboard/normalPrice_productaprovel'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import AmazonProductsPage from '../modules/amazon-product/AmazonProductsPage'
import AmazonAccountsPage from '../modules/amazon-account/AmazonAccountsPage'
import ChannelsPage from '../modules/channels/ChannelsPage'
import CurrencyPage from '../modules/currency/CurrencyPage'
import TotalproductsShow from '../pages/dashboard/totalproductsShow'
import {ProductHeader} from '../modules/products/detail/ProductHeader'
import ProductsPage from '../modules/products/ProductsPage'

import TotalBuyersshow from '../pages/dashboard/totalBuyersshow'
import TotalAllAdmin from '../pages/dashboard/totalAllAdmin'
import Products_atrisk from '../pages/dashboard/normalprice_atrisk'
import Products_atbonus from '../pages/dashboard/saleprice_atbonus'
import Normalprice_atrisk from '../pages/dashboard/normalprice_atrisk'
import Normalprice_atbonus from '../pages/dashboard/normalprice_atbonus'
import Saleprice_atrisk from '../pages/dashboard/saleprice_atrisk'
import Saleprice_atbonus from '../pages/dashboard/saleprice_atbonus'
import {Saleprice_productaprovel} from '../pages/dashboard/SalePrice_productaprovel'
// import Sellercloud from '../modules/seller-Cloud/Sellercloud'
import Sellercloud from '../modules/seller-account/SellerAccountPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  // const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const RolesPage = lazy(() => import('../modules/roles/RolesPage'))
  const BuyersPage = lazy(() => import('../modules/buyers/BuyersPage'))
  const UsersPage = lazy(() => import('../modules/users/UsersPage'))
  const ProductsPage = lazy(() => import('../modules/products/ProductsPage'))
  const PricingAmazonProductPage = lazy(
    () => import('../modules/amazon-pricing-products/AmazonProductsPage')
  )

  const IbsProductsSyncPage = lazy(() => import('../modules/ibs-products-sync/IbsProductsSyncPage'))
  const AmazonProductsPage = lazy(() => import('../modules/amazon-product/AmazonProductsPage'))
  const SalerAccount = lazy(() => import('../modules/seller-account/SellerAccountPage'))
  const SellerProduct = lazy(() => import('../modules/seller-products/SellerProductsPage'))
  const GoflowProduct = lazy(() => import('../modules/goflow-products/GogflowProductsPage'))
  // const Sellercloud = lazy(() => import('../modules/seller-Cloud/Sellercloud'))

  const AmazonAccountsPage = lazy(() => import('../modules/amazon-account/AmazonAccountsPage'))
  const ChannelsPage = lazy(() => import('../modules/channels/ChannelsPage'))
  const CurencyPage = lazy(() => import('../modules/channels/ChannelsPage'))
  {
    /* only for design*/
  }
  const IBSProductSync = lazy(() => import('../modules/apps/IBS-product-sync/UsersPage'))
  const AllProducts = lazy(() => import('../modules/apps/products/UsersPage'))
  const ProductPage = lazy(() => import('../modules/apps/single-product/ProductPage'))
  const SYNCProducts = lazy(() => import('../modules/apps/sync-products/UsersPage'))
  const AtRiskProducts = lazy(() => import('../modules/apps/products-risk/UsersPage'))
  const UnderApprovalProducts = lazy(
    () => import('../modules/apps/under-approval-products/UsersPage')
  )
  const OnSale = lazy(() => import('../modules/apps/on-sale-products/UsersPage'))
  const ApprovalProducts = lazy(() => import('../modules/apps/approved-products/UsersPage'))
  const EditProductsPricing = lazy(() => import('../modules/apps/edit-products-pricing/UsersPage'))
  const CreateSalePricing = lazy(() => import('../modules/apps/create-sale-pricing/UsersPage'))
  const IBSproducts = lazy(() => import('../modules/IBS-products/ibsproducts'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='dashboard/Normalprice-productapproval'
          element={<Normalprice_productaprovel />}
        />
        <Route path='dashboard/Saleprice-productapproval' element={<Saleprice_productaprovel />} />
        <Route path='dashboard/total-products' element={<TotalproductsShow />} />
        <Route path='dashboard/total-buyers' element={<TotalBuyersshow />} />
        <Route path='dashboard/total-admins' element={<TotalAllAdmin />} />
        <Route path='dashboard/normalprice-atrisk' element={<Normalprice_atrisk />} />
        <Route path='dashboard/normalprice-atmargin' element={<Normalprice_atbonus />} />
        <Route path='dashboard/saleprice-atrisk' element={<Saleprice_atrisk />} />
        <Route path='dashboard/saleprice-atmargin' element={<Saleprice_atbonus />} />
        <Route path='products' element={<ProductsPage />} />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='products/*'
          element={
            <SuspensedView>
              <ProductsPage />
            </SuspensedView>
          }
        />
          <Route
          path='ibs-product/*'
          element={
            <SuspensedView>
              <IBSproducts />
            </SuspensedView>
          }
        />
        

        <Route
          path='amazon-product/*'
          element={
            <SuspensedView>
              <PricingAmazonProductPage />
            </SuspensedView>
          }
        />
        <Route
          path='ibs-products-sync/*'
          element={
            <SuspensedView>
              <IbsProductsSyncPage />
            </SuspensedView>
          }
        />
        <Route
          path='roles/*'
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />
        <Route
          path='buyers/*'
          element={
            <SuspensedView>
              <BuyersPage />
            </SuspensedView>
          }
        />
        <Route
          path='users/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='amazon/*'
          element={
            <SuspensedView>
              <AmazonProductsPage />
            </SuspensedView>
          }
        />

        <Route
          path='seller-account/*'
          element={<SuspensedView>{<SalerAccount />}</SuspensedView>}
        />

        <Route
          path='seller-product/*'
          element={<SuspensedView>{<SellerProduct />}</SuspensedView>}
        />

        <Route
          path='goflow-product/*'
          element={<SuspensedView>{<GoflowProduct />}</SuspensedView>}
        />

        {/* <Route
          path='seller-cloud/*'
          element={
            <SuspensedView>
              <Sellercloud />
            </SuspensedView>
          }
        /> */}

        <Route
          path='aws/*'
          element={
            <SuspensedView>
              <AmazonAccountsPage />
            </SuspensedView>
          }
        />

        <Route
          path='currency/*'
          element={
            <SuspensedView>
              <CurrencyPage />
            </SuspensedView>
          }
        />
        <Route
          path='channels/*'
          element={
            <SuspensedView>
              <ChannelsPage />
            </SuspensedView>
          }
        />
        {/*<Route*/}
        {/*    path='apps/user-management/*'*/}
        {/*    element={*/}
        {/*        <SuspensedView>*/}
        {/*            <UsersPage/>*/}
        {/*        </SuspensedView>*/}
        {/*    }*/}
        {/*/>*/}
        {/* Page Not Found */}

        <Route path='*' element={<Navigate to='/error/404' />} />

        <Route
          path='apps/IBS-product-sync/*'
          element={
            <SuspensedView>
              <IBSProductSync />
            </SuspensedView>
          }
        />
        <Route
          path='apps/products/*'
          element={
            <SuspensedView>
              <AllProducts />
            </SuspensedView>
          }
        />
        <Route
          path='apps/single-product/*'
          element={
            <SuspensedView>
              <ProductPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/SYNC-products/*'
          element={
            <SuspensedView>
              <SYNCProducts />
            </SuspensedView>
          }
        />

        <Route
          path='apps/At-Risk-Products/*'
          element={
            <SuspensedView>
              <AtRiskProducts />
            </SuspensedView>
          }
        />
        <Route
          path='apps/under-approval-products/*'
          element={
            <SuspensedView>
              <UnderApprovalProducts />
            </SuspensedView>
          }
        />
        <Route
          path='apps/on-sale/*'
          element={
            <SuspensedView>
              <OnSale />
            </SuspensedView>
          }
        />
        <Route
          path='apps/approval-products/*'
          element={
            <SuspensedView>
              <ApprovalProducts />
            </SuspensedView>
          }
        />

        <Route
          path='apps/edit-products-pricing/*'
          element={
            <SuspensedView>
              <EditProductsPricing />
            </SuspensedView>
          }
        />
        <Route
          path='apps/create-sale-pricing/*'
          element={
            <SuspensedView>
              <CreateSalePricing />
            </SuspensedView>
          }
        />
        {/* onlu design routes-----------*/}
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
