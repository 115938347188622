// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const openAddRoleModal = createSlice({
  name:"openAddRoleModal",
  initialState: {
    isToggled: false,
  },
  reducers:{
    openedAddRoleModal: state => {
        state.isToggled = !state.isToggled;
      },
  }
}) 
export default openAddRoleModal.reducer;
export const {openedAddRoleModal} = openAddRoleModal.actions;