// src/redux/slices/priceSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ProductPriceState {
  [id: string]: {
    currentPrice: number;
    previousPrice: number | null;
  };
}

const initialState: ProductPriceState = {}

const amazonProductPriceSlice = createSlice({
  name: 'amazonProductPrice',
  initialState,
  reducers: {
    UpdateAmazonProductPrice: (state, action: PayloadAction<{ id: any, currentPrice: number,previousPrice: number | null }>) => {
      const { id, currentPrice,previousPrice } = action.payload
      if (state[id]) {
        state[id].previousPrice = previousPrice; // Set the previous price
        state[id].currentPrice = currentPrice; // Update the current price
      } else {
        state[id] = {
          currentPrice: currentPrice,
          previousPrice: previousPrice || null, // Set previous price or null if not provided
        };
      }
    }
  }
})
export default amazonProductPriceSlice.reducer;
export const { UpdateAmazonProductPrice } = amazonProductPriceSlice.actions
