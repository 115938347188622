import {FC, useState, createContext, useContext, useMemo} from 'react'
import {
    WithChildren,
} from '../../../../../_metronic/helpers'
import {
    ID,
    initialListView,
    ListViewContextProps,
    initialProductListView,
    ProductsListViewContextProps,
} from '../../../../../_metronic/helpers/crud-helper/custom-models'
import {
    calculatedGroupingIsDisabled,
    calculateIsAllDataSelected,
    groupingOnSelect,
    groupingOnSelectAll,
} from '../../../../../_metronic/helpers/crud-helper/custom-helpers'

import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'

const ListViewContext = createContext<ProductsListViewContextProps>(initialProductListView)

const ListViewProvider: FC<WithChildren> = ({children}) => {
    const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
    const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
    const [itemIdForReassign, setItemIdForReassign] = useState<ID>(initialListView.itemIdForUpdate)
    const [ItemIdForViewSales, setItemIdForViewSales] = useState<any>(initialListView.itemIdForUpdate);
    const [ItemIdForViewSalesEdit, setItemIdForViewSalesEdit] = useState<any>(initialListView.itemIdForUpdate);
    const {isLoading} = useQueryResponse()
    const data = useQueryResponseData()
    const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
    const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected,""), [data, selected])
    const [editingRow, setEditingRow] = useState<ID>(initialProductListView.editingRow);
    const [inlineEditPrice, setInlineEditPrice] = useState<number>(initialProductListView.inlineEditPrice);
    return (
        <ListViewContext.Provider
            value={{
                selected,
                itemIdForUpdate,
                setItemIdForUpdate,
                itemIdForReassign,
                setItemIdForReassign,
                ItemIdForViewSales,
                setItemIdForViewSales,
                ItemIdForViewSalesEdit,
                setItemIdForViewSalesEdit,
                disabled,
                isAllSelected,
                onSelect: (id: ID) => {
                    groupingOnSelect(id, selected, setSelected)
                },
                onSelectAll: () => {
                    groupingOnSelectAll(isAllSelected, setSelected, data)
                },
                clearSelected: () => {
                    setSelected([])
                },
                editingRow,
                setEditingRow,
                inlineEditPrice,
                setInlineEditPrice
            }}
        >
            {children}
        </ListViewContext.Provider>
    )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
