import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  activeProductLink: [],
};

const activeProductLinkSlice = createSlice({
  name: 'activeProductLink',
  initialState,
  reducers: {
    setActiveProductLink: (state, action) => {
      const { productId, channelId } = action.payload;

      const existingChannelIndex = state.activeProductLink.findIndex(
        channel => channel.productId === productId && channel.channelId === channelId
      );

      if (existingChannelIndex !== -1) {
        state.activeProductLink.splice(existingChannelIndex, 1);
      } else {
        state.activeProductLink.push({ productId, channelId });
      }
    },
    clearActiveProductLink: (state) => {
      state.activeProductLink = [];
    },
  },
});

export const { setActiveProductLink, clearActiveProductLink } = activeProductLinkSlice.actions;

export default activeProductLinkSlice.reducer;
