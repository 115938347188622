// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import productActionSlice from './productActionSlice';
import productIbsReducer from './productIbsSelected';
import channelIdActionSlice from './channelIdActionSlice';
import channelDetailEditSlice from './channelDetailEditSlice';
import pricingChannelDetailEditSlice from './pricingChannelDetailEditSlice';
import  showhiddenProducts  from './hiddenproducts';
import updateBuyerCode from './updateBuyerCode';
import azureId from './azureId';
import azureIdOpen from './azureIdOpen';
import  UpdateAmazonProductPrice  from './amazonProductPrice';
import  UpdateAmazonFeedAction  from './amazonFeeddata';
import pageNumber from './pageNumber';
import openaddRolemodal from './openaddRolemodal';
import activeChannelReducer from './activeChannelSlice'
import activeProductLinkReducer from './activeProductLinkSlice'
import sellerProductpayloaddata  from './ sellerproductshowinventory';
import toggle  from './sellerCloudApicalltoggle';
import setProductId  from './sellerCloudApicalltoggle';
import Checktoggle  from './Checktogle';
import Spinnerr  from './Spinner';
import arrowiconToggleproductId  from './ArrowiconToggleproductId';
import Channellength  from './ChannelDatalength';
import SellerproductShowchannel from './SellerproductShowchannel';
import accordianToggle  from './spinnerForAccordion';
import  toggleforfetchData  from './ApicallToggle';

const store = configureStore({
  reducer: {
    productionAction: productActionSlice,
    channelIdAction:  channelIdActionSlice,
    updateBuyerCode:updateBuyerCode,
    channelDetailEdit:channelDetailEditSlice,
    pricingChannelDetailEditSlice:pricingChannelDetailEditSlice,
    showhiddenProducts : showhiddenProducts,
    azureId:azureId,
    azureIdOpen:azureIdOpen,
    UpdateAmazonProductPrice:UpdateAmazonProductPrice,
    UpdateAmazonFeedAction:UpdateAmazonFeedAction,
    pageNumber:pageNumber,
    openaddRolemodal:openaddRolemodal,
    activeChannel: activeChannelReducer,
    activeProductLink: activeProductLinkReducer,
    sellerProductpayloaddata:sellerProductpayloaddata,
    toggle:toggle,
    setProductId:setProductId,
    ibsSelectedAction: productIbsReducer,
    Checktoggle:Checktoggle,
    Spinnerr:Spinnerr,
    arrowiconToggleproductId:arrowiconToggleproductId,
    Channellength:Channellength,
    SellerproductShowchannel:SellerproductShowchannel,
    accordianToggle:accordianToggle,
    toggleforfetchData:toggleforfetchData
    // Add more reducers if needed
  }
});
export default store;
