// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'

const spinnerForAccordion = createSlice({
  name:"Checktogle",
  initialState: {
    isToggled: null,
  },
  reducers:{
      accordianToggle(state,action){
         state.isToggled = action.payload
      },
  }
}) 
export default spinnerForAccordion.reducer;
export const {accordianToggle} = spinnerForAccordion.actions;