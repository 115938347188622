// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'

const Checktogle = createSlice({
  name:"Checktogle",
  initialState: {
    isToggled: null,
  },
  reducers:{
      Checktoggle(state,action){
         state.isToggled = action.payload
      },
  }
}) 
export default Checktogle.reducer;
export const {Checktoggle} = Checktogle.actions;