// reducers/counterReducer.js
import { createSlice } from '@reduxjs/toolkit'
const ApicallToggle = createSlice({
  name:"ApicallToggle",
  initialState: {
    isToggled: null,
  },
  reducers:{
    // ToggleforfetchData: state => {
    //     state.isToggled = !state.isToggled;
    //   },
      ToggleforfetchData(state,action){
        state.isToggled = action.payload
     },
  }
}) 
export default ApicallToggle.reducer;
export const {ToggleforfetchData} = ApicallToggle.actions;